import useSwr from "swr";
import {getHttpClient, swrFetcher} from "../../../../api";
import LoadingSkeleton from "../../../../components/LoadingSkeleton";
import {FiCheckCircle} from "react-icons/fi";
import {getAccessToken} from "../../../../utils/access_token_handler";
import {Link} from "react-router-dom";
import React from "react";
import ConfirmButton from "../../../../components/ConfirmButton";

function AmazonInstructions(){
    const {data, isLoading, mutate} = useSwr('/integrations/amazon/connected_regions',swrFetcher)
    const disconnect = ()=>{
        getHttpClient().delete('/integrations/amazon/connected_regions').then(()=>{
          mutate()
        })
    }
    if(isLoading){
        return <LoadingSkeleton height={36.5} width={200} />
    }else{
        return <>
            {
                data?.error ? <>
                        <p className="text-danger">Your amazon connection is invalid. Please re-connect your account</p>
                    </> : null
            }
            {
                data?.regions.length > 0 ? <>
        <span className="text-success d-inline-flex align-items-center">
          <FiCheckCircle className={"me-2"} /> <span>Connected</span>
        </span>
                    {
                        data?.regions.map(({metadata, region})=>{
                            return (<div key={region}>
                                    <small className={"text-muted"}>
                                        {metadata.queryParameter.replace('{insertCampaign}', 'goaffpro')
                                            .replace('{insertAdGroupId}', '{{ref_code}}')
                                            .replace('{insertCreativeId}', '')
                                        }
                                    </small> <br/>
                                </div>
                            )
                        })
                    }

                    <div className="mt-2">
              <ConfirmButton
                  className={"btn btn-link text-danger px-0"}
                  message={"Are you sure you want to disconnect the amazon attribution integration ?"}
                             onClick={disconnect}>Disconnect</ConfirmButton>
                    </div>
                </> : <>
                    {
                        window.top !== window.self ? <a className={"btn btn-secondary"}
                                                        target="_blank" href={`https://app.goaffpro.com/t/${getAccessToken()}?then=/install/amazon`}>
                                Setup
                            </a> :
                            <Link to={"/install/amazon"} className={"btn btn-secondary"}>Setup</Link>
                    }
                </>
            }
        </>
    }
}
export default AmazonInstructions
