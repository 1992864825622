import axios from "axios";
import * as storage from "./utils/storage";
import { getAccessToken } from "./utils/access_token_handler";
import {getItem} from "./utils/storage";
export const baseURL =
  process.env.NODE_ENV === "development"
    ? "https://api-server-3.goaffpro.com"
    : "https://api.goaffpro.com";

export const appURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://app.goaffpro.com";

export function getHttpClient(config:Record<string, string> = {}) {
  const token = getAccessToken();
  const headers = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  if (
    config &&
    config.useMultiSiteToken &&
    getItem("multi_site_access_token")
  ) {
    headers["Authorization"] = `Bearer ${getItem(
      "multi_site_access_token"
    )}`;
  }

  return axios.create({
    baseURL,
    // timeout: 30000,
    headers,
    withCredentials: true,
    ...config,
  });
}

export function login({ email, password, site_domain }) {
  return getHttpClient().post("/login", {
    email,
    password,
    site_domain,
  });
}

export function loginSiteOwner({ email, password }) {
  return getHttpClient()
    .post("/users/login", {
      email,
      password,
    })
    .then(({ data }) => {
      if (data.access_token) {
        storage.setItem("access_token", data.access_token);
      }
      return data;
    });
}

export async function uploadImage(file) {
  // get presigned post
  return new Promise(async (resolve, reject) => {
    const { url, fields } = await getHttpClient()
      .post("/v1/admin/creatives/preSignedUrl", {
        name: file.name,
      })
      .then(({ data }) => data);
    const formData = new FormData();

    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append("content-type", file.type);
    formData.append("file", file);
    await axios.post(url, formData);
    const uri = `https://media.goaffpro.com/${fields.key}`;
    // once uploaded, get image dimensions
    const img = new Image();
    img.onload = function () {
      //@ts-ignore
      console.log("loaded", this.width, this.height);
      resolve({
        //@ts-ignore
        width: this.width,
        //@ts-ignore
        height: this.height,
        url: uri,
        size: file.size,
        type: file.type,
      });
    };
    img.onerror = reject;
    console.log(uri);
    img.src = uri;
  });
}

export const swrFetcher = (url: string) =>
  getHttpClient()
    .get(url)
    .then((res) => res.data);

export const privateFetcher = (path) =>
  axios.get(`https://custom-api.goaffpro.app/${path}`).then((res) => res.data);

export interface SiteExtractedMetadata {
  description?: string;
  banner?: {
    url: string;
    width?: number;
    height?: number;
  }
  keywords?: string;
  site_name?: string;
  icon: Array<{ url: string; width?: number; height?: number }>;
  manifest?: string;
  logos: Array<{ url: string | null; width?: number; height?: number }>;
}
export async function getMetadataFromWebsite(url): Promise<SiteExtractedMetadata | undefined>{
  return await getHttpClient().post('/extras/get_website_metadata',{
    website: url
  }).then(({data})=>data.metadata)
      .catch((e)=>{
        console.log(e)
        return;
      })
}
