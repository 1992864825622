import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PaypalPayoutsSection from './paypalpayouts/PaypalPayoutsSection';
import RecaptchaSection from './recaptcha/RecaptchaSection';
import ShopifyAccountSync from './shopify-accounts/ShopifyAccountSync';
import MailchimpSection from './mailchimp/MailchimpSection';
import OmnisendSettings from './omnisend/OmnisendSettings';
import KlaviyoSection from './klaviyo/KlaviyoSection';
import RechargeSettingSection from './rechargepayments/RechargeSettingSection';
import MailerliteSection from './mailerlite/MailerliteSection';
import {connect, useDispatch, useSelector} from 'react-redux';
import ConvertkitSection from './convertkit/ConvertkitSection';
import BoldSubscription from './boldsubscription/BoldSubscription';
import PayWhirl from './PayWhirl/PayWhirl';
import ZipifyOcuSection from './zipifyocu/ZipifyOCUSection';
import {Link} from 'react-router-dom';
import ActiveCampaign from './ActiveCampaign';
import settingsStateToProps from '../settingsStateToProps';
import CampaignMonitor from './CampaignMonitor';
import StripeIntegrationSection from './stripe/StripeIntegrationSection';
import SettingsSection from '../components/SettingSection';
import Modal from '../../../components/Modal';
import CopyButton from '../../../components/CopyButton';
import FormGroup from '../../../components/form/FormGroup';
import {updateSetting} from '../../../redux/settings/settingsAction';
import ReactSwitch from 'react-switch';
import Youtube from '../../../components/video/Youtube';
import InputGroup from '../../../components/form/InputGroup';
import {getHttpClient} from '../../../api';
import {SettingsMap} from '../../../components/VideoAndHelpSectionMap';
import VimeoOTT from './VimeoOTT';
import YampiCheckout from './YampiCheckout';
import useToggle from '../../../components/hooks/useToggle';
import useVisitsTrackingCode
  from '../../../components/hooks/useVisitsTrackingCode';
import SquarespaceScheduling from './SquarespaceScheduling';
import GoogleTagManagerSettingsSection
  from './google-tag-manager/GoogleTagManagerSettingsSection';
import MemberVault
  from '../../installers/sdk/integration-instructions/MemberVault';
import ThinkificIntegrationSteps
  from '../../installers/sdk/integration-instructions/ThinkificIntegrationSteps';
import ShopbaseIntegration
  from '../../installers/sdk/integration-instructions/ShopbaseIntegration';
import AmazonInstructions
  from '../../installers/sdk/integration-instructions/AmazonInstructions';
import CartPanda from '../../installers/sdk/integration-instructions/CartPanda';
import OrderTags from '../advanced/sections/OrderTags';
import CustomerTags from '../advanced/sections/CustomerTags';
import {getAppOauthUrl} from '../../installers/squareup/connectSquare';
import {FiArrowRight, FiCheckCircle} from 'react-icons/fi';
import useObjectState from '../../../components/hooks/useObjectState';
import Input from '../../../components/form/Input';
import {ConfirmModal, useConfirm} from '../../../components/ConfirmModal';

function IntegrationsTab({integration}) {
  return (
      <>
        <TipAltiSection/>
        <PaypalPayoutsSection/>
        <RecaptchaSection/>
        {
            ['shopify', 'woocommerce','wix','bigcommerce'].indexOf(integration) > -1 &&
            <ShopifyAccountSync/>
        }
        {
          integration === "shopify" ? <>
            <OrderTags/>
            <CustomerTags/>
          </> : null
        }
        <SettingsSection title={"Amazon Attribution"} subtitle={"Do you have a brand on amazon ? Connect your amazon brand with your program for seamless affiliate tracking from sales on amazon"}>
          <AmazonInstructions />
        </SettingsSection>
        <MailchimpSection/>
        <OmnisendSettings/>
        <KlaviyoSection/>
        <ZendeskIntegration />
        <ConvertkitSection/>
        <MailerliteSection/>
        {/*<HubspotSection/>*/}
        <ActiveCampaign/>
        <CampaignMonitor/>
        {
          integration === "squarespace" && <SquarespaceCheckout />
        }
        {
            process.env.NODE_ENV === 'development' &&
            <StripeIntegrationSection/>
        }
        {
          integration === 'squarespace' ? <>
            <SquarespaceScheduling/>
          </> : null
        }
        <GoogleTagManagerSettingsSection />
        {
          integration === 'sdk' ? <>

            <ShopbaseIntegrationSection/>
            <SubblyCheckout/>
            <ThinkificConversionTracking />
          </> : null
        }
        <MemberVaultConversionTracking />
        <VimeoOTT/>
        <ThirdPartyCheckout/>
        <CartPandaCheckout />
        {
            integration === 'shopify' &&
            <>
              <YampiCheckout/>
              <BoldSubscription/>
              <ZipifyOcuSection/>
            </>
        }
        {
          ['shopify', 'bigcommerce'].indexOf(integration) > -1 ? <>
            <RechargeSettingSection/>
          </> : null
        }

         <SquareIntegration/>
        {
            ['weebly', 'wix'].indexOf('integration') > -1 && <>

            </>
        }
        <PayWhirl/>
        <p className={'text-center'}>Your favorite integration not listed
          ? <Link to={'/contact'}>Let us know</Link> and we will build it for
          you</p>
      </>
  );
}


function ZendeskIntegration(){
  const [visible, toggle] = useToggle(false)
  const partner_portal_subdomain = useSelector((state)=>state.settings.partner_portal_subdomain)
  const remote_login = `https://${partner_portal_subdomain}/login`;
  const zendesk_sso_url = `https://${partner_portal_subdomain}/sso/zendesk`;
  const [data, setData, replace] = useObjectState({
    zendesk_shared_secret:"",
    zendesk_subdomain:"",
    zendesk_contact_enabled: false
  })
  const settings = useSelector((state)=>{
    return {
      zendesk_shared_secret: state.settings.zendesk_shared_secret,
      zendesk_subdomain: state.settings.zendesk_subdomain,
      zendesk_contact_enabled: !!state.settings.zendesk_contact_enabled
    }
  })
  useEffect(()=>{
    replace(settings)
  },[])
  const dispatch = useDispatch()
  const reset = ()=>{
    dispatch(updateSetting({
      zendesk_shared_secret:null,
      zendesk_subdomain:null,
      zendesk_contact_enabled: null
    }))
    toggle()
  }
  const save = (e)=>{
    if(e){
      e.preventDefault()
      e.stopPropagation()
    }
    dispatch(updateSetting({
      zendesk_shared_secret: data.zendesk_shared_secret,
      zendesk_subdomain: data.zendesk_subdomain,
      zendesk_contact_enabled: data.zendesk_contact_enabled
    }))
  }
  const confirmAndDelete = ()=>{
    confirm({
      title: 'Reset Zendesk Integration',
      message: 'Are you sure you want to reset zendesk integration ?',
      onConfirm: reset
    })
  }
  const confirm = useConfirm();

  return <SettingsSection title={"Zendesk SSO"} subtitle={"Give logged in affiliates access to your zendesk helpdesk"}>
    {
      settings.zendesk_subdomain ? <>
        <div className={"d-flex justify-content-between"}>
          <div className="text-success d-inline-flex align-items-center"><FiCheckCircle className={"me-2"} />Connected</div>
          <div>
              <button className={'btn btn-link text-danger p-0'} type={"button"} onClick={confirmAndDelete}>Reset integration</button>
            </div>
        </div>
        <p>Your affiliates can access also access zendesk at <br/>
          <a href={zendesk_sso_url}>{zendesk_sso_url}</a></p>
      </> : null
    }
      <button className={"btn btn-link p-0"} onClick={toggle}>{
        settings.zendesk_subdomain ? "Edit" : "Setup"
      }</button>

    <Modal okText={'Submit'} title={'Zendesk Single Sign On'} visible={visible} onSubmit={save} onClose={toggle}>
        <FormGroup required label={"Zendesk Subdomain"}>
          <Input
              value={data.zendesk_subdomain}
              onChange={setData('zendesk_subdomain')}
              suffix={".zendesk.com"} placeholder={"yourstore"}  type={"text"} />
        </FormGroup>
      <p>Generate a JSON Web Token from your zendesk admin center and paste it below<br/>
        <a title={"Click to open zendesk guide"} href={"https://support.zendesk.com/hc/en-us/articles/4408845838874-Enabling-JWT-single-sign-on#topic_gds_ydj_zj"} target={"_blank"}>Guide here</a></p>
      <p>For the Remote Login URL, you can use <br/><CopyButton title={"Click to copy"} copyBtnText={remote_login} text={remote_login} className={"btn btn-link p-0"} />
      </p>
      <FormGroup required label={"Zendesk JWT"}>
        <Input
            type={"password"}
            value={data.zendesk_shared_secret}
            onChange={setData('zendesk_shared_secret')}
            />
      </FormGroup>
      <FormGroup required label={"Replace affiliate contact page"}>
        <div>
          <ReactSwitch checked={data.zendesk_contact_enabled} onChange={setData('zendesk_contact_enabled')} />
        </div>
      </FormGroup>

    </Modal>
  </SettingsSection>
}

function MemberVaultConversionTracking(){
  const [visible, toggle] = useToggle()
  return <SettingsSection title={"MemberVault"} subtitle={"Connect Goffpro with your Membervault product pages"}>
    <button onClick={toggle} className="btn btn-link px-0">See instructions</button>
    <Modal title={"MemberVault Integration"} size={"lg"} visible={visible} onClose={toggle}>
      <p>Follow the steps below to integration MemberVault with Goaffpro</p>
      <MemberVault />
      <p className="text-center text-success">
        That's it. The system is ready to track conversions for you.
      </p>
    </Modal>
  </SettingsSection>
}

function EcwidCheckoutIntegration() {
  return <SettingsSection title={'Ecwid Checkout'}
                          subtitle={'Enable this option if your store is using Ecwid iFrame for checkout'}>

  </SettingsSection>;
}


export function SubblyCheckoutIntegrationInstructions() {
  const {
    script,
    website_match,
  } = useVisitsTrackingCode();
  const conversionTrackingCode = useMemo(() => {
    return `<script type="text/javascript">
    var urlObj = Object.fromEntries(new URL(document.location.href).searchParams.entries())
    window.goaffpro_order = {
        id:"{{order.id}}",
        total:"{{order.amount}}",
        currency:"{{order.currency}}",
        customer:{
          id:"{{customer.id}}",
          first_name:"{{customer.first_name}}",
          last_name:"{{customer.last_name}}",
          email:"{{customer.email}}"
        },
        coupons: [urlObj.coupon, urlObj.giftCard].filter((i)=>i),
        ref: urlObj.ref,
      }
      if(typeof goaffproTrackConversion !== "undefined"){
        goaffproTrackConversion(window.goaffpro_order);
      }else if(window.top !== window.self){
        const script = document.createElement('script')
        script.src = "https://api.goaffpro.com/loader.js?shop=${website_match}"
        document.head.appendChild(script);
      }</script>`;
  }, []);
  return <>
    <h3>Step 1</h3>
    <p>In your subbly website admin panel. Go to Dashboard on your website
      builder and navigate to Site Settings. and paste the following code in the
      Code Injection section </p>
    <img
        src={'/images/subbly/subbly-code-injection.gif'}/>
    <textarea value={script} className={'form-control mb-1'}
              readOnly></textarea>
    <CopyButton className={'btn btn-outline-primary btn-link'} text={script}/>
    <hr/>
    <h3>Step 2</h3>
    <ol>
      <li>Go to <a
          href={'https://www.subbly.co/admin/marketing/conversion-tracking'}>Conversion tracking page</a>
      </li>
      <li>Paste the code below in the Conversion Tracking Code field</li>
      <img src={'/images/subbly/subbly-conversion-tracking.png'}
           style={{maxWidth: '100%'}}/>
    </ol>
    <textarea value={conversionTrackingCode}
              className={'form-control mb-1'} readOnly></textarea>
    <CopyButton className={'btn btn-outline-primary btn-link'}
                text={conversionTrackingCode}/>
  </>;

}

function SubblyCheckout() {
  const [visible, toggle] = useToggle();
  return <SettingsSection title={'Subbly Checkout'}
                          subtitle={'Use this option if your store checkout is on subbly platform'}>
    <button onClick={toggle} className={'btn btn-link px-0'}>See instructions
    </button>
    <Modal visible={visible} onClose={toggle}
           title={'Subbly checkout integration'}>
      <SubblyCheckoutIntegrationInstructions/>
    </Modal>
  </SettingsSection>;
}
function CartPandaCheckout() {
  const [visible, toggle] = useToggle();
  return <SettingsSection title={'Cart Panda Checkout'}
                          subtitle={'Use this option if your store checkout is on Cart Panda platform'}>
    <button onClick={toggle} className={'btn btn-link px-0'}>See instructions
    </button>
    <Modal visible={visible} onClose={toggle}
           title={'Cart Panda checkout integration'}>
      <CartPanda/>
    </Modal>
  </SettingsSection>;
}

function ShopbaseIntegrationSection() {
  return <SettingsSection title={'Shopbase.com Checkout'}
                          subtitle={'Enable this option if your store is on Shopbase.com platform'}>
    <ShopbaseIntegration />
  </SettingsSection>;
}

function TipAltiSection() {
  const [showModal, setShowModal] = useState(false);
  const [masterKey, setMasterKey] = useState(null);
  const [payerName, setPayerName] = useState(null);
  const [loading, setLoading] = useState(false);
  const tipaltiPayer = useSelector((state) => state.settings.tipaltiPayer);
  const tipaltiMasterKey = useSelector(
      (state) => state.settings.tipaltiMasterKey);
  const tipaltiEnabled = useSelector((state) => state.settings.tipaltiEnabled);

  const dispatch = useDispatch();
  useEffect(() => {
    setMasterKey(tipaltiMasterKey);
    setPayerName(tipaltiPayer);
  }, [tipaltiPayer, tipaltiMasterKey]);

  function submit(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setLoading(true);
    dispatch(updateSetting({
      tipaltiPayer: payerName,
      tipaltiMasterKey: masterKey,
      tipaltiEnabled: true,
      pmtipalti: true,
    })).then(() => {
      setLoading(false);
      setShowModal(false);
    });
  }
  if(!tipaltiMasterKey) return null; // tipalti integration removed
  return <SettingsSection title={'Tipalti'} helpLink={SettingsMap.get(
      'Integrations').Tipalti.helpLink} videoID={SettingsMap.get(
      'Integrations').Tipalti.videoID}
                          subtitle={'Process payments to your affiliates in bulk via Tipalti'}>
    <Modal visible={showModal}
           okText={'Submit'}
           disabled={!payerName || !masterKey}
           onCancel={() => setShowModal(false)}
           onSubmit={submit}
           confirmLoading={loading}
           title={'Tipalti'} onClose={() => setShowModal(false)}>
      <Youtube videoId={'WIZNd_BGMW0'}/>
      <FormGroup label={'Payer name'}>
        <input type="text" className="form-control"
               value={payerName}
               placeholder={'eg. YourCompanyName'}
               onChange={(e) => setPayerName(e.target.value)}

        />
      </FormGroup>
      <FormGroup label={'Master key'}>
        <input type="password" className={'form-control'}
               placeholder={'eg. CwEHBhHDPd4/8k8+OsCYGDeBNLI7Ol0uCAiYLKApGWxAhMYb2ZxWodJP9PWFpj8F'}

               value={masterKey} onChange={(e) => setMasterKey(e.target.value)}

        />
      </FormGroup>
      <h3>IPN Instructions</h3>
      <p className="text-muted">Copy and paste the following links (see the
        video above) to complete the API integration</p>
      <IPNInstructions/>
    </Modal>
    {
      payerName && masterKey ?
          <><ReactSwitch checked={tipaltiEnabled}
                         onChange={(tipaltiEnabled) => {
                           dispatch(updateSetting({
                             tipaltiEnabled,
                             pmtipalti: tipaltiEnabled,
                           }));
                         }}/>
            <br/>
            <button className={'btn btn-link px-0'}
                    onClick={() => setShowModal(true)}>Change
            </button>
          </> :
          <button className={'btn btn-secondary'}
                  onClick={() => setShowModal(true)}>Setup</button>

    }
  </SettingsSection>;
}

function IPNInstructions() {
  const [siteHash, setSitehash] = useState('pqDLi8kc8oSZFaD5sz0r');

  useEffect(() => {
    getHttpClient().get('/tipalti/ipn_hash').then(({data}) => {
      setSitehash(data.hash);
    });
  }, []);
  return <>
    <RenderIPNURL label={'IPN payment completed'} hash={siteHash}
                  type={'payment_completed'}/>
    <RenderIPNURL label={'IPN payment cancelled'} hash={siteHash}
                  type={'payment_cancelled'}/>
    <RenderIPNURL label={'IPN payment details changed'} hash={siteHash}
                  type={'payment_details_changed'}/>
  </>;
}

function RenderIPNURL({label, type, hash}) {
  const url = 'api.goaffpro.com/tipalti/ipn/' + type + '/' + hash;
  return <FormGroup label={label}>
    <InputGroup suffix={<CopyButton className={'btn btn-sm btn-link p-0'}
                                    text={url}/>}
                prefix={'https://'} type="text" value={url}
                className="form-control"/>
  </FormGroup>;
}

function ThirdPartyCheckout() {
  const [visible, setVisible] = useState(false);
  return <SettingsSection title={'Third party checkout'}
                          helpLink={SettingsMap.get(
                              'Integrations').ThirdPartyCheckout.helpLink}
                          videoID={SettingsMap.get(
                              'Integrations').ThirdPartyCheckout.videoID}
                          subtitle={'Connect third party checkout apps with the app. eg. Yampi Checkout'}>
    <button className={'btn btn-link px-0'} onClick={() => setVisible(true)}>See
      instructions
    </button>
    <ThirdPartyCheckoutModal visible={visible}
                             onClose={() => setVisible(false)}/>
  </SettingsSection>;
}

function ThinkificConversionTracking(){
  const [visible, toggle] = useToggle();
 return  <SettingsSection title={'Thinkific Conversion Tracking'}
                   subtitle={'Connect thinkific order tracking with goaffpro'}>
    <button className={'btn btn-link px-0'} onClick={toggle}>See
      instructions
    </button>
    <Modal visible={visible} size={"lg"} onClose={toggle} title={"Integration Steps"}>
      <ThinkificIntegrationSteps />
      <p>That's it</p>
    </Modal>
  </SettingsSection>;
}

function ThirdPartyCheckoutModal({visible, onClose}) {
  const integration = useSelector((state) => state.settings.integration);
  const website_match = useSelector((state) => state.settings.website_match);
  const cart_level_tracking = useSelector(
      (state) => state.settings.cart_level_tracking);
  const dispatch = useDispatch();
  const changed = useCallback((cart_level_tracking) => {
    dispatch(updateSetting({
      cart_level_tracking,
    }));
  }, [dispatch]);
  const script = `<script type="text/javascript" src="https://api.goaffpro.com/loader.js?shop=${website_match}"></script><script type="text/javascript">goaffproTrackConversion('last_order')</script>`;
  return <Modal title={'Third party checkout'} visible={visible}
                onClose={onClose}>
    <p>Copy the following code and paste this in your custom checkout's order
      status page (or thank you page or order confirmation page)</p>
    <img src={'https://i.imgur.com/gt88mFF.png'} style={{width: '100%'}}
         alt={'Sample scripts area'}/>
    <FormGroup label={'Code'}>
            <textarea readOnly className={'form-control'} value={script}>
            </textarea>
    </FormGroup>
    <div className="text-center">
      <CopyButton className={'btn btn-outline-primary'} text={script}
                  copyBtnText={'Copy code'}/>
    </div>
    {
      integration === 'shopify' ?
          <div className={'row border-top pt-4 mt-2'}>
            <div className="col-md-8">
              <h6>Cart based tracking</h6>
              <p className="text-muted">
                Adds tracking to the cart metadata for cross window/browser
                tracking. Note: This will also do sale attribution on abandoned
                cart recovery
              </p>
            </div>
            <div className="col-md-4">
              <ReactSwitch checked={cart_level_tracking} onChange={changed}/>
            </div>
          </div>
          : null

    }
  </Modal>;

}

function SquarespaceCheckout() {
  const squarespace_auto_conversion = useSelector(
      (state) => state.settings.squarespace_auto_conversion);
  const dispatch = useDispatch();
  const toggle = useCallback((squarespace_auto_conversion) => {
      dispatch(updateSetting({
        squarespace_auto_conversion
      }));
  }, [dispatch]);
  return <SettingsSection title={'Squarespace Conversion'}
                          subtitle={'Enable conversion tracking from squarespace checkout'}>
    <ReactSwitch checked={squarespace_auto_conversion} onChange={toggle} />
  </SettingsSection>;
}

function SquareIntegration(props) {
  const square_checkout_connected = useSelector(
      (state) => state.settings.square_checkout_connected);

  return <SettingsSection title={'Square Checkout'} helpLink={SettingsMap.get(
      'Integrations').SquareCheckout.helpLink} videoID={SettingsMap.get(
      'Integrations').SquareCheckout.videoID}
                          subtitle={'Connect square checkout for conversion tracking'}>

    {
      square_checkout_connected ?
          <div className={"space-between"}>
            <div className="text-success"><FiCheckCircle
                style={{marginLeft: 4}}/> Connected
            </div>
            <RemoveIntegrationModal />
          </div> :
          <Link to={getAppOauthUrl()}>Setup</Link>
    }
  </SettingsSection>;
}

function RemoveIntegrationModal(){
  const website_match = useSelector((state)=>state.settings.website_match)
  const visitsTracker = `<script type="text/javascript" src="https://api.goaffpro.com/loader.js?shop=${website_match}"></script>`
  const conversionTracker = `${visitsTracker}
        <script type="text/javascript">
        var goaffproOrder = {
            id : "{orderid}",
            number:"{txid}",
            total: "{total}",
            subtotal:"{subtotal}",
            tax: "{tax}",
            customer:{
                name:"{name}",
                email:"{email}"
            }
        }
        goaffproTrackConversion(goaffproOrder);
    </script>`
  const [visible, toggleDisconnectModal]= useToggle()
  const dispatch = useDispatch();
  const disconnect = useCallback(() => {
      toggleDisconnectModal()
      setTimeout(function(){
        getHttpClient().post('/integrations/square/uninstall',{
          revoke_only_access_token:false // revoke permissions as well
        })
        .then(()=>{
          console.log('authorization revoked')
        }).catch((e)=>{
          console.log(e)
        })
        return dispatch(updateSetting({
          square_checkout_connected: null,
          square_access_token: null,
          square_refresh_token: null,
        }));
      }, 2000)
  }, [dispatch]);
  return <>
    <button onClick={toggleDisconnectModal}
            className="btn btn-outline-danger mt-2">Disconnect
    </button>
    <Modal visible={visible} size={"lg"} title={'Disconnect Square Checkout'}>
      <p>To completely disconnect app's integration with your store, use the steps below</p>
      <h4>Step 1</h4>
      <p>Remove the conversion tracking code from Square admin panel</p>
      <ol>
        <li>Open your Square store's admin panel</li>
        <li className={'d-inline-flex align-items-center'}>In the sidebar, click&nbsp;<kbd>Settings</kbd> <FiArrowRight/> <kbd>Tracking
            Tools</kbd></li>
        <li>Scroll down to <mark>Checkout Tracking Section</mark> and
          click <kbd>Edit checkout tracking</kbd></li>
        <li><strong>Remove</strong> the code that looks like below from the <strong>Custom Tracking - Receipt page</strong></li>
        <FormGroup>
          <textarea rows={5} className="form-control" spellCheck={false}
                    value={conversionTracker}/>
        </FormGroup>
      </ol>
      <hr/>
      <h4>Step 2</h4>
      <p>Click the button below to revoke app's access to your store's data</p>
      <button className={'btn btn-danger'} onClick={disconnect}>Revoke access</button>
    </Modal>
  </>
}

function WooCommerceIntegration() {
  const {consumer_key, consumer_secret} = useSelector((data) => {
    const {consumer_key, consumer_secret} = data.settings;
    return {
      consumer_key, consumer_secret,
    };
  });
  return <SettingsSection title={'WooCommerce'}
                          subtitle={'Woocommerce integration for wordpress sites'}>
    {
      consumer_key && consumer_secret ? <p>Connected <br/>
        <Link to={'/'} className={'btn px-0 btn-link'}>Update keys</Link>
      </p> : <>
        <button className="btn btn-secondary">Setup</button>
      </>
    }

  </SettingsSection>;

}

export default connect(settingsStateToProps(['integration']), null)(
    IntegrationsTab);
